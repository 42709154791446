import {lazy} from "react";
import {Navigate} from "react-router-dom";

import AuthGuard from "./auth/AuthGuard";
import {authRoles} from "./auth/authRoles";

import Loadable from "./components/Loadable";
import MatxLayout from "./components/MatxLayout/MatxLayout";

// SESSION PAGES
const NotFound = Loadable(lazy(() => import("app/views/sessions/NotFound")));
const UserLogin = Loadable(lazy(() => import("app/views/sessions/UserLogin01")));
const JwtRegister = Loadable(lazy(() => import("app/views/sessions/UserRegister")));
const ForgotPassword = Loadable(lazy(() => import("app/views/sessions/ForgotPassword01")));
const ResetPassword = Loadable(lazy(() => import("app/views/sessions/ResetPassword01")));
const EmailVerification = Loadable(lazy(() => import("app/views/sessions/EmailVerification01")));
const Resume = Loadable(lazy(() => import("app/views/resume/UserProfile")));
const Profile = Loadable(lazy(() => import("app/views/resume/ProfileEdit")));
const Expired = Loadable(lazy(() => import("app/views/sessions/LinkExpired01")));
const VExpired = Loadable(lazy(() => import("app/views/sessions/VerificationExpired")));
// DASHBOARD PAGE
const Analytics = Loadable(lazy(() => import("app/views/adminboard/Analytics")));

const UserAnalytics = Loadable(lazy(() => import("app/views/userboard/Analytics")));

const Products = Loadable(lazy(() => import("app/views/products/AppTable")));

const BDProducts = Loadable(lazy(() => import("app/views/bdproduct/AppTable")));

const BDOrders = Loadable(lazy(() => import("app/views/bdorders/AppTable")));

const Productlist = Loadable(lazy(() => import("app/views/productlist/AppTable")));

const Influencer = Loadable(lazy(() => import("app/views/influencers/AppTable")));

const Orders = Loadable(lazy(() => import("app/views/orders/AppTable")));

const Swagger = Loadable(lazy(() => import("app/swagger/SwaggerUI")));

const Overview = Loadable(lazy(() => import("app/views/charts/realtime/Overview")));

const InfluncerChart = Loadable(lazy(() => import("app/views/charts/realtime/Influencers")));

const Guide = Loadable(lazy(() => import("app/views/guide/AppTable")));

const routes = [
    {
        element: (
            <AuthGuard>
                <MatxLayout/>
            </AuthGuard>
        ),
        children: [
            // adminboard route
            {path: "/adminboard/default", element: <Analytics/>, auth: authRoles.admin},
            {path: "/bdproduct", element: <BDProducts/>, auth: authRoles.admin},
            {path: "/bdorders", element: <BDOrders/>, auth: authRoles.admin},
            {path: "/charts/overview", element: <Overview/>, auth: authRoles.admin},
            {path: "/charts/influencers", element: <InfluncerChart/>, auth: authRoles.admin},
            {path: "/userboard/default", element: <UserAnalytics/>, auth: authRoles.customer},
            {path: "/profile", element: <Profile/>, auth: authRoles.customer},
            {path: "/resume", element: <Resume/>, auth: authRoles.customer},
            // products route
            {path: "/products", element: <Products/>, auth: authRoles.customer},
            {path: "/productlist", element: <Productlist/>, auth: authRoles.all},
            // influencer route
            {path: "/influencers", element: <Influencer/>, auth: authRoles.admin},
            // orders route
            {path: "/orders", element: <Orders/>, auth: authRoles.customer},
            {path:"/swagger", element: <Swagger/>, auth: authRoles.admin},
            {path:"/how-it-works", element: <Guide/>, auth: authRoles.influencer}
        ]
    },

    // session pages route
    {path: "/404", element: <NotFound/>},
    {path: "/login", element: <UserLogin/>},
    {path: "/signup", element: <JwtRegister/>},
    {path: "/resume", element: <Resume/>},
    {path: "/token-expired", element: <Expired/>},
    {path: "/verify-expired", element: <VExpired/>},
    {path: "/session/forgot-password", element: <ForgotPassword/>},
    {path: "/session/reset-password/:token", element: <ResetPassword/>},
    {path: "/session/confirm/:token", element: <EmailVerification/>},
    {path: "/", element: <Navigate to="/login"/>},
    {path: "*", element: <NotFound/>}
];

export default routes;
