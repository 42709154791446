export default function NOVILogo({className}) {

    return (
        <img
            width="100%"
            height="35px"
            src="/assets/images/NOVI.png"
            alt="Logo"
            className={className}
        />
    );
}
